import React, {useEffect} from 'react';
import {
  getRawCookie,
  removeDocumentReferrer,
  removeImpactClickIdCookie,
  removeImpactMediaPartnerIdCookie,
  setDocumentReferrer,
  setImpactClickIdCookie,
  setImpactMediaPartnerIdCookie,
} from '../../helpers';
import {navigate} from 'gatsby';

const Holidays2022 = () => {
  useEffect(() => {
    // Setting document referrer
    const referrer =
      typeof document !== 'undefined' &&
      document &&
      document.referrer &&
      document.referrer.indexOf('https://hexomatic.com') === -1 &&
      document.referrer;
    if (referrer && getRawCookie('document_referrer')) {
      removeDocumentReferrer();
    }
    referrer && setDocumentReferrer(referrer);

    // Setting Impact irclickid in cookies
    const url = window.location.href;
    if (url && url.indexOf('irclickid=') > -1) {
      if (getRawCookie('__irClickId')) {
        removeImpactClickIdCookie();
      }
      const id = url.slice(url.indexOf('irclickid=') + 10);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactClickIdCookie(idFin);
      removeImpactMediaPartnerIdCookie();
    } else if (url && url.toLocaleLowerCase().indexOf('mediapartnerid=') > -1) {
      if (getRawCookie('__mediaPartnerId')) {
        removeImpactMediaPartnerIdCookie();
      }
      const id = url.slice(url.toLocaleLowerCase().indexOf('mediapartnerid=') + 15);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactMediaPartnerIdCookie(idFin);
      removeImpactClickIdCookie();
    }
  }, []);

  useEffect(() => {
    navigate('/pricing/');
  }, []);

  return (
    <></>
  );
};

export default Holidays2022;
